import { inject } from '@angular/core';
import {
  CanActivateFn,
  CanMatchFn,
  Router,
} from '@angular/router';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';


export const isAuthorizedUserGuard: CanActivateFn & CanMatchFn = () => {

  const router = inject(Router);
  const authService = inject(MsalService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status !== InteractionStatus.None),
      map(() => authService.instance.getAllAccounts().length > 0),
      distinctUntilChanged(),
      tap(isSignedIn => {
        if (!isSignedIn) {
          router.navigate(['sign-in']);
        }
      })
    );
}
