import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import {
  CanActivateFn,
  CanMatchFn,
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

export const forbiddenPageGuard: CanActivateFn & CanMatchFn = () => {

  const http = inject(HttpClient);
  const authService = inject(MsalService);
  
  const account = authService.instance.getActiveAccount();

  // TODO: Check circular dependency
  if (!account) return of(false);

  return http.get(`${environment.api}/api/access-status`)
    .pipe(
      map(_ => false),
      catchError(_ => of(true)),
    );
}