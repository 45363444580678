import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { distinctUntilChanged, filter, map, tap } from 'rxjs';


export const isNotAuthenticatedGuard: CanActivateFn = () => {

  const router = inject(Router);
  const authService = inject(MsalService);
  const msalBroadcastService = inject(MsalBroadcastService);

  return msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status !== InteractionStatus.None),
      map(() => authService.instance.getAllAccounts().length > 0),
      distinctUntilChanged(),
      tap(isSignedIn => {
        if (isSignedIn) {
          router.navigate(['']);
        }
      }),
      map(isSignedIn => !isSignedIn)
    );
}
